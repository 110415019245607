2
<template>
  <Modal
    width="550px"
    :header="headerName"
    :show="show"
    :loadingSubmit="loading"
    @close="close"
    @submit="submit"
  >
    <q-form ref="campaignUpdateForm" v-on:submit.prevent>
      <q-card flat v-if="isDraftRequest">
        <q-card-section>
          <p>Are you sure you want to submit this request?</p>
          <ul class="font-semibold mt-4">
            <li>{{ `${data?.campaignCode} - ${data?.campaignName}` }}</li>
          </ul>
        </q-card-section>
      </q-card>

      <q-card flat v-else-if="isSetupRequest">
        <q-card-section>
          <p>Are you sure you want to setup this request?</p>
          <ul class="font-semibold mt-4">
            <li>{{ `${data?.campaignCode} - ${data?.campaignName}` }}</li>
          </ul>
        </q-card-section>

        <q-separator />

        <q-card-section>
          <p class="font-semibold text-gray-7 mb-4">
            Please indicate the Suki Coupons:
          </p>

          <FormTag
            v-model="coupons"
            label="* Coupon Code"
            hint="* Input coupon code and press enter to add"
            :rules="[VALIDATION.requiredArray]"
            tabindex="1"
            outlined
          />
        </q-card-section>
      </q-card>

      <q-card flat v-else-if="isActiveRequest">
        <q-card-section>
          <p>Are you sure you want to make this request active?</p>
          <ul class="font-semibold mt-4">
            <li>{{ `${data?.campaignCode} - ${data?.campaignName}` }}</li>
          </ul>
        </q-card-section>
      </q-card>

      <q-card flat v-else>
        <q-card-section>
          <p>Are you sure you want to {{ actionName }} this request?</p>
          <ul class="font-semibold mt-4">
            <li>{{ `${data?.campaignCode} - ${data?.campaignName}` }}</li>
          </ul>
        </q-card-section>

        <div v-if="isRequestCancel || isRequestReject">
          <q-separator />

          <q-card-section>
            <p class="text-gray-7 mb-4">
              Please indicate why you want to {{ actionName }} this request.
            </p>
            <FormInput
              v-model="remarks"
              type="textarea"
              label="* Remarks"
              :rules="[VALIDATION.required]"
              tabindex="1"
              outlined
            />
          </q-card-section>
        </div>
      </q-card>
    </q-form>
  </Modal>
</template>

<script>
import { ref, computed, watch } from 'vue'

import Modal from '@/components/Modal'
import { FormInput, FormTag } from '@/components/inputs'

import { campaign } from '@/constants'
import { VALIDATION, Toast } from '@/tools'

export default {
  name: 'UpdateCampaignModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: { Modal, FormInput, FormTag },
  setup(props, { emit }) {
    const { showToast } = Toast()

    const cancelLists = ['requested', 'approved', 'rejected']
    const campaignUpdateForm = ref(null)
    const remarks = ref(null)
    const coupons = ref([])
    const isDraftRequest = computed(
      () => props.type === 'draft' && props.action === 'requested'
    )

    const isRequestCancel = computed(
      () => cancelLists.includes(props.type) && props.action === 'cancelled'
    )
    const isRequestApprove = computed(
      () => props.type === 'requested' && props.action === 'approved'
    )
    const isRequestReject = computed(
      () => props.type === 'requested' && props.action === 'rejected'
    )
    const isSetupRequest = computed(
      () => props.type === 'approved' && props.action === 'setup'
    )
    const isActiveRequest = computed(
      () => props.type === 'setup' && props.action === 'active'
    )
    const actionName = computed(() => {
      return campaign.filter(
        item =>
          item.name === (isRequestCancel.value ? 'cancelled' : props.action)
      )[0]?.action
    })
    const actionStatus = computed(() => {
      return campaign.filter(
        item =>
          item.name === (isRequestCancel.value ? 'cancelled' : props.action)
      )[0]?.value
    })
    const headerName = computed(() => {
      if (isDraftRequest.value) {
        return 'Submit Request'
      } else {
        if (actionName.value) {
          return `${
            actionName.value.charAt(0).toUpperCase() + actionName.value.slice(1)
          } Request`
        } else {
          return null
        }
      }
    })

    const close = () => {
      emit('close')
    }

    const submit = () => {
      campaignUpdateForm.value.validate().then(success => {
        if (success) {
          if (isSetupRequest.value && coupons.value.length === 0) {
            showToast('Please indicate at least 1 Suki Coupon', 'danger')
          } else if (isRequestCancel.value && !remarks.value) {
            showToast(
              'Please indicate why you want to cancel the request',
              'danger'
            )
          } else {
            const data = {
              id: props?.data?.id,
              status: actionStatus.value,
              remarks: remarks.value,
              coupons: coupons.value
            }

            emit('submit', data)
          }
        }
      })
    }

    watch([() => props.data], () => {
      remarks.value = null
      coupons.value = []
    })

    return {
      VALIDATION,
      isDraftRequest,
      isRequestCancel,
      isRequestApprove,
      isRequestReject,
      isSetupRequest,
      isActiveRequest,
      campaignUpdateForm,
      remarks,
      coupons,
      actionName,
      headerName,
      close,
      submit
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  display: block;
  list-style: disc outside none;
  padding: 0 0 0 40px;
}
</style>
