<template>
  <Modal
    :header="data?.campaignName"
    :show="show"
    :loading="loadingLists || loadingCoupons"
    width="700px"
    cancelLabel="Ok"
    @close="close"
    closeOnly
  >
    <q-card flat>
      <q-card-section>
        <h6 class="bg-white relative z-10 w-20 ml-4 px-2">Details</h6>
        <div class="mx-1 p-4 column q-gutter-md border rounded border-gray">
          <div>
            <div class="item">
              <div>Campaign Code:</div>
              <span>{{ campaignData?.campaignCode }}</span>
            </div>

            <div class="item">
              <div>Campaign Name:</div>
              <span>{{ campaignData?.campaignName }}</span>
            </div>

            <div class="item">
              <div>Campaign Duration:</div>
              <span>
                {{ campaignData?.dateFrom }} to {{ campaignData?.dateTo }}
              </span>
            </div>

            <div class="item">
              <div>Mechanics:</div>
              <span>{{ campaignData?.mechanics }}</span>
            </div>

            <q-separator class="my-4 -ml-4" />

            <div class="item">
              <div>Customer Segment:</div>
              <span>{{ campaignData?.segmentName }}</span>
            </div>

            <div class="item">
              <div>Status:</div>
              <span>
                <Badge
                  :variant="getStatusData(campaignData?.status)?.color"
                  :text="getStatusData(campaignData?.status)?.label"
                />
              </span>
            </div>

            <div class="item">
              <div>Remarks:</div>
              <span>{{ campaignData?.remarks }}</span>
            </div>

            <q-separator class="my-4 -ml-4" />

            <div class="item mb-2" v-if="campaignCoupons">
              <div>Suki Coupons:</div>
              <span class="row gap-2">
                <Badge
                  variant="default"
                  v-for="(coupon, key) in campaignCoupons"
                  :text="coupon"
                  :key="key"
                />
              </span>
            </div>

            <div class="item">
              <div>Attachment:</div>
              <span class="text-xs text-grey-7 hover:underline">
                <a
                  :href="`${urlAPI}attachments/${campaignData?.file}`"
                  target="_blank"
                >
                  <q-icon name="attach_file" size="xs" />
                  {{ campaignData?.file }}
                </a>
              </span>
            </div>

            <div class="item">
              <div>Campaign Type:</div>
              <span>
                {{ campaignData?.campaignDiscountTypeName }}
              </span>
            </div>

            <div class="item">
              <div>Send Notification:</div>
              <span>
                <Badge
                  :variant="
                    campaignData?.campaignSend === true ? 'success' : 'danger'
                  "
                  :text="campaignData?.campaignSend === true ? 'Yes' : 'No'"
                />
              </span>
            </div>

            <div class="item" v-if="campaignData?.campaignSend === true">
              <div>Notification Message:</div>
              <span>
                {{ campaignData?.campaignMessage }}
              </span>
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </Modal>
</template>

<script>
import { ref, watch, onBeforeMount, watchEffect } from 'vue'
import Modal from '@/components/Modal'
import Badge from '@/components/Badge'

import { campaign } from '@/constants'
import { DATE, Toast, fetchData } from '@/tools'

export default {
  name: 'ViewCampaignModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  components: { Modal, Badge },
  setup(props, { emit }) {
    const { showToast } = Toast()

    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const {
      data: dataCoupons,
      error: errorCoupons,
      loading: loadingCoupons,
      get: getCoupons
    } = fetchData()

    const urlAPI = ref(process.env.VUE_APP_API_BASE_URL)
    const campaignData = ref(null)
    const campaignCoupons = ref(null)

    const close = () => {
      emit('close')
    }

    const getCustomerSegment = id => {
      getLists(`/v1/campaign/info/${id}`)
      getCoupons(`/v1/campaign/suki-coupons/${id}`)
    }

    const getStatusData = data => {
      return campaign.filter(item => item.value === data)[0]
    }

    watchEffect(() => {
      if (props.show && props?.data?.id) {
        getCustomerSegment(props?.data?.id)
      }
    })

    onBeforeMount(() => {
      watch([dataLists, errorLists, loadingLists], () => {
        if (loadingLists.value) {
          campaignData.value = null
        } else {
          if (errorLists?.value) {
            showToast(
              errorLists?.value?.message ??
                'There was a problem fetching campaign.',
              'danger'
            )
          } else if (dataLists?.value) {
            const data = dataLists?.value?.result

            campaignData.value = {
              campaignCode: data?.code ?? '-',
              campaignName: data?.name ?? '-',
              dateFrom: DATE.toFriendlyDate(data?.date_from),
              dateTo: DATE.toFriendlyDate(data?.date_to),
              mechanics: data?.mechanics ?? '-',
              file: data?.filename,
              segmentName: data?.segment_name ?? '-',
              remarks: data?.remarks ?? '-',
              status: data?.status,
              createdAt: data?.created_at,
              campaignDiscountTypeName: data?.campaign_type_name ?? '-',
              campaignSend:
                data?.notification_message && data?.notification_message !== '',
              campaignMessage: data?.notification_message ?? '-'
            }
          }
        }
      })

      watch([dataCoupons, errorCoupons, loadingCoupons], () => {
        if (loadingCoupons.value) {
          campaignCoupons.value = null
        } else {
          if (dataCoupons?.value) {
            const data = dataCoupons?.value?.coupons[0]

            campaignCoupons.value = data?.promotion_id
              .replace(/\[|\]/g, '')
              .split(',')
          }
        }
      })
    })

    return {
      loadingLists,
      loadingCoupons,
      urlAPI,
      campaignData,
      campaignCoupons,
      close,
      getStatusData
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  @apply mb-4;
  @apply flex flex-col gap-0;
  @apply md:mb-2 md:flex-row md:gap-2;

  > div {
    @apply w-36;
    @apply font-bold;
    min-width: 10rem;
  }
}
</style>
