<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader breadcrumb="Campaign" title="Campaigns Lists" />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Create New"
        size="lg"
        outline
        @click="goToCreatePage"
        v-if="isPrincipal"
      >
        <template v-slot:icon>
          <q-icon name="add_circle" size="md" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <Loader :loading="loadingCount" v-if="loadingCount" />
    <div class="col q-col-gutter-md" v-else>
      <div>
        <q-tabs
          v-model="tabSelected"
          class="bg-primary text-white shadow-2"
          align="justify"
          outside-arrows
          mobile-arrows
        >
          <q-tab
            v-for="tab in tabs"
            :key="tab.name"
            :name="tab.name"
            :icon="tab.icon"
            v-show="tab.enabled"
            @click="onToggleTab(tab.value)"
          >
            <Badge
              variant="danger"
              :text="tab.count"
              floating
              v-if="tab.count > 0 && hasBadge(tab.name)"
            />

            <div>
              {{ tab.name }}
              <div v-if="tab.count > 0 && !hasBadge(tab.name)">
                <Badge :text="tab.count" />
              </div>
            </div>
          </q-tab>
        </q-tabs>
      </div>
      <div class="row items-start q-col-gutter-md">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingLists"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
            :filter="search"
            @onRequest="onRequest"
          >
            <template v-slot:top-left>
              <h6>
                <p class="text-grey-6" v-if="search">
                  <small> Search result for: "{{ search }}" </small>
                </p>
              </h6>
            </template>

            <template v-slot:top-right>
              <q-input
                dense
                rounded
                outlined
                debounce="300"
                v-model="search"
                placeholder="Search"
              >
                <template v-slot:append>
                  <q-icon
                    :name="search ? 'close' : 'search'"
                    :class="search && 'cursor-pointer'"
                    @click="onClearSearch"
                  />
                </template>
              </q-input>
            </template>

            <template v-slot:body-cell-campaignCode="props">
              <q-td :props="props">
                <div class="multiLineLabel">
                  <span class="textMaxLine">
                    {{ props.row.campaignCode }}
                  </span>
                </div>
              </q-td>
            </template>

            <template v-slot:body-cell-campaignName="props">
              <q-td :props="props">
                <div
                  class="multiLineLabel cursor-pointer hover:underline"
                  @click="onOpenViewModal(props.row)"
                >
                  <span class="textMaxLine">
                    {{ props.row.campaignName }}
                  </span>
                </div>
              </q-td>
            </template>

            <template v-slot:body-cell-customerSegment="props">
              <q-td :props="props">
                <div class="multiLineLabel">
                  <span class="textMaxLine">
                    {{ props.row.customerSegment }}
                  </span>
                </div>
              </q-td>
            </template>

            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <Button icon="more_vert" round flat>
                  <q-menu>
                    <q-list dense style="min-width: 200px" class="text-primary">
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenViewModal(props.row)"
                      >
                        <q-avatar icon="article" size="lg" />
                        <q-item-section>View</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="goToEditPage(props.row)"
                        v-if="isEditAllowed"
                      >
                        <q-avatar icon="edit" size="lg" />
                        <q-item-section>Edit</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenUpdateModal('deleted', props.row)"
                        v-if="isDeleteAllowed"
                      >
                        <q-avatar icon="delete" size="lg" />
                        <q-item-section>Delete</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenUpdateModal('requested', props.row)"
                        v-if="isRequestAllowed"
                      >
                        <q-avatar icon="file_upload" size="lg" />
                        <q-item-section>Submit Request</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenUpdateModal('approved', props.row)"
                        v-if="isApprovedAllowed"
                      >
                        <q-avatar icon="thumb_up_alt" size="lg" />
                        <q-item-section>Approve</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenUpdateModal('rejected', props.row)"
                        v-if="isRejectedAllowed"
                      >
                        <q-avatar icon="thumb_down_alt" size="lg" />
                        <q-item-section>Reject</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenUpdateModal('setup', props.row)"
                        v-if="isSetupAllowed"
                      >
                        <q-avatar icon="read_more" size="lg" />
                        <q-item-section>Setup Request</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenUpdateModal('active', props.row)"
                        v-if="isActiveAllowed"
                      >
                        <q-avatar icon="campaign" size="lg" />
                        <q-item-section>Set as Active</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="onOpenUpdateModal('cancelled', props.row)"
                        v-if="isCancelAllowed"
                      >
                        <q-avatar icon="cancel" size="lg" />
                        <q-item-section>Cancel</q-item-section>
                      </q-item>
                      <q-item
                        clickable
                        v-close-popup
                        @click="onDownloadMembers(props.row)"
                        v-if="isDownloadAllowed"
                      >
                        <q-avatar icon="download" size="lg" />
                        <q-item-section>Download members</q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </Button>
              </q-td>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    :footer="false"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <div class="p-4">
      <h5 class="q-mb-md">Campaign Lists</h5>
      <p>
        {{ HELP.CAMPAIGN_LISTS.description }}
      </p>

      <q-separator class="q-my-md" />

      <h6 class="q-mb-md text-sm">
        {{ HELP.CAMPAIGN_LISTS.status.description }}
      </h6>
      <Table
        :rows="HELP.CAMPAIGN_LISTS.status.lists"
        :columns="HELP.TABLE"
        :limit="0"
        hide-bottom
      >
        <template v-slot:body="props">
          <q-tr>
            <q-td>
              <strong>{{ props.row.name }}</strong>
            </q-td>
            <q-td :style="{ whiteSpace: 'normal' }">
              {{ props.row.description }}
            </q-td>
          </q-tr>
        </template>
      </Table>

      <div v-if="isPrincipal">
        <q-separator class="q-my-md" />

        <div class="q-mb-md">
          <h6 class="text-sm mb-2">
            {{ HELP.CAMPAIGN_LISTS.create.description }}
          </h6>
          <ol>
            <li v-for="list in HELP.CAMPAIGN_LISTS.create.lists" :key="list.id">
              {{ list.text }}
            </li>
          </ol>
        </div>
      </div>

      <div v-if="isRetailer">
        <q-separator class="q-my-md" />

        <div class="q-mb-md">
          <h6 class="text-sm mb-2">
            {{ HELP.CAMPAIGN_LISTS.manage.description }}
          </h6>
          <ol>
            <li v-for="list in HELP.CAMPAIGN_LISTS.manage.lists" :key="list.id">
              {{ list.text }}
            </li>
          </ol>
        </div>
      </div>

      <q-separator class="q-my-md" />

      <div class="q-mb-md">
        <h6 class="text-sm mb-2">
          {{ HELP.CAMPAIGN_LISTS.other.description }}
        </h6>
        <ol>
          <li v-for="list in HELP.CAMPAIGN_LISTS.other.lists" :key="list.id">
            {{ list.text }}
          </li>
        </ol>
      </div>
    </div>
  </Modal>

  <ViewCampaignModal
    :show="showViewModal"
    :data="selectedData"
    @close="onCloseViewModal"
  />

  <UpdateCampaignModal
    :show="showUpdateModal"
    :type="tabSelected"
    :action="actionSelected"
    :data="selectedData"
    :loading="loadingUpdate"
    @close="onCloseUpdateModal"
    @submit="onSubmitUpdateModal"
  />
</template>

<script>
import { ref, watch, onBeforeMount, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { omit } from 'lodash'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import Loader from '@/components/Loader'
import Badge from '@/components/Badge'

import ViewCampaignModal from './components/ViewCampaignModal'
import UpdateCampaignModal from './components/UpdateCampaignModal'

import store from '@/store'
import { HELP, campaign, campaignType } from '@/constants'
import {
  FORMAT,
  VALIDATION,
  DATE,
  Toast,
  getIconUrl,
  fetchData,
  EXPORT
} from '@/tools'

export default {
  name: 'CampaignLists',
  components: {
    PageHeader,
    PageContent,
    Button,
    Table,
    Modal,
    Loader,
    Badge,
    ViewCampaignModal,
    UpdateCampaignModal
  },
  setup() {
    const $q = useQuasar()
    const router = useRouter()
    const { showToast } = Toast()
    const state = store?.state
    const isPrincipal = ref(state?.accountType?.principal)
    const isRetailer = ref(state?.accountType?.retailer)
    const isSuki = ref(state?.accountType?.suki)
    const campaignTab =
      router?.currentRoute?.value?.params?.tab ??
      (isPrincipal.value
        ? 'draft'
        : isRetailer.value
        ? 'requested'
        : isSuki.value
        ? 'approved'
        : 'completed')

    const {
      data: dataCount,
      error: errorCount,
      loading: loadingCount,
      get: getCount
    } = fetchData()

    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const {
      data: dataList,
      error: errorList,
      loading: loadingList,
      get: getList
    } = fetchData()

    const {
      data: dataUpdate,
      error: errorUpdate,
      loading: loadingUpdate,
      post: postUpdate
    } = fetchData()

    const {
      data: dataUpdateSukiCoupon,
      error: errorUpdateSukiCoupon,
      loading: loadingUpdateSukiCoupon,
      post: postUpdateSukiCoupon
    } = fetchData()

    const {
      data: dataDownloadMembers,
      error: errorDownloadMembers,
      loading: loadingDownloadMembers,
      get: getDownloadMembers
    } = fetchData()

    const tabs = ref(campaign.filter(item => item.tab === true))
    const actionSelected = ref(null)
    const tabSelected = ref(campaignTab)
    const tabID = ref(
      campaign.filter(item => item.name === tabSelected.value)[0].value
    )
    const isDraft = computed(() => tabSelected.value === 'draft')
    const isRequested = computed(() => tabSelected.value === 'requested')
    const isRejected = computed(() => tabSelected.value === 'rejected')
    const isApproved = computed(() => tabSelected.value === 'approved')
    const isSetup = computed(() => tabSelected.value === 'setup')
    const isActive = computed(() => tabSelected.value === 'active')
    const isCompleted = computed(() => tabSelected.value === 'completed')
    const isRequestAllowed = computed(
      () => isDraft.value || (isRejected.value && isPrincipal.value)
    )
    const isEditAllowed = computed(
      () =>
        (isDraft.value || isRequested.value || isRejected.value) &&
        isPrincipal.value
    )
    const isApprovedAllowed = computed(
      () => isRequested.value && isRetailer.value
    )
    const isRejectedAllowed = computed(
      () => isRequested.value && isRetailer.value
    )
    const isSetupAllowed = computed(() => isApproved.value && isSuki.value)
    const isActiveAllowed = computed(() => isSetup.value && isSuki.value)
    const isDeleteAllowed = computed(() => isDraft.value && isPrincipal.value)
    const isCancelAllowed = computed(
      () =>
        (isRequested.value || isRejected.value || isApproved.value) &&
        isPrincipal.value
    )
    const isDownloadAllowed = computed(
      () =>
        (isApproved.value || isSetup.value) &&
        (isRetailer.value || isSuki.value)
    )
    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )

    const notif = ref(null)
    const showModal = ref(false)
    const showViewModal = ref(false)
    const showDeleteModal = ref(false)
    const showUpdateModal = ref(false)
    const selectedData = ref(null)
    const submittedData = ref(null)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)
    const search = ref('')
    const filterForm = ref(null)
    const salesTableData = ref([])
    const salesTableHeader = computed(() => {
      const columns = [
        {
          name: 'campaignCode',
          label: 'Campaign Code',
          field: 'campaignCode',
          align: 'left',
          sortable: true,
          style: 'max-width: 200px',
          headerStyle: 'max-width: 200px'
        },
        {
          name: 'campaignName',
          label: 'Campaign Name',
          field: 'campaignName',
          align: 'left',
          sortable: true,
          style: 'max-width: 300px',
          headerStyle: 'max-width: 300px'
        },
        {
          name: 'campaignFrom',
          label: 'From',
          field: 'campaignFrom',
          align: 'center',
          sortable: true
        },
        {
          name: 'campaignTo',
          label: 'To',
          field: 'campaignTo',
          align: 'center',
          sortable: true
        },
        {
          name: 'customerSegment',
          label: 'Customer Segment',
          field: 'customerSegment',
          align: 'left',
          sortable: true,
          style: 'max-width: 300px',
          headerStyle: 'max-width: 300px'
        }
      ]

      if (isRetailer.value || isSuki.value) {
        columns.push({
          name: 'principalName',
          label: 'Principal Name',
          field: 'principalName',
          align: 'center',
          sortable: true
        })
      }

      if (isPrincipal.value || isSuki.value) {
        columns.push({
          name: 'retailerName',
          label: 'Retailer Name',
          field: 'retailerName',
          align: 'center',
          sortable: true
        })
      }

      columns.push({ name: 'action', align: 'right', label: ' ' })

      return columns
    })

    const hasBadge = type => {
      const principalAllowedBadge = ['draft', 'rejected']
      const retailerAllowedBadge = ['requested']
      const sukiAllowedBadge = ['approved', 'setup']

      return isPrincipal.value
        ? principalAllowedBadge.includes(type)
        : isRetailer.value
        ? retailerAllowedBadge.includes(type)
        : isSuki.value
        ? sukiAllowedBadge.includes(type)
        : false
    }

    const onToggleTab = data => {
      const campaignTabName = campaign.filter(item => item.value === data)[0]
        .name

      tabID.value = data
      store.methods.updatePreviousTab(campaignTabName)
      history.pushState({}, null, `/campaign/lists/${campaignTabName}`)
      getCampaignLists()
    }

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const goToCreatePage = () => {
      router.push({ name: 'campaign_create' })
    }

    const goToEditPage = data => {
      router.push({ name: 'campaign_update', params: { id: data?.id } })
    }

    const exportTable = () => {
      EXPORT.exportToExcel({
        header: salesTableHeader.value,
        data: salesTableData.value,
        filename: 'campaign_lists'
      })
    }

    const onDownloadMembers = data => {
      getDownloadMembers(`v1/campaign/suki-campaign-customers/${data?.id}`)
    }

    const onOpenViewModal = data => {
      showViewModal.value = true
      selectedData.value = data
    }

    const onCloseViewModal = () => {
      showViewModal.value = false
      selectedData.value = null
    }

    const onOpenUpdateModal = (type, data) => {
      showUpdateModal.value = true
      selectedData.value = data
      actionSelected.value = type
    }

    const onCloseUpdateModal = () => {
      showUpdateModal.value = false
      selectedData.value = null
    }

    const onSubmitUpdateModal = data => {
      if (data) {
        submittedData.value = data
        getCustomerSegment(data?.id)
      }
    }

    const onClearSearch = () => {
      search.value = ''
    }

    const onUpdateCampaign = () => {
      const data = submittedData.value

      const payload = {
        status: data?.status
      }

      if (actionSelected.value === 'cancelled') {
        payload.remarks = `Cancelled - ${data?.remarks}`
      }

      if (actionSelected.value === 'rejected') {
        payload.remarks = `Rejected - ${data?.remarks}`
      }

      postUpdate(`v1/campaign/update-status/${data?.id}`, payload)
    }

    const getCampaignCount = () => {
      getCount('/v1/campaign/count-by-status')
    }

    const getCustomerSegment = id => {
      getList(`/v1/campaign/info/${id}`)
    }

    const getCampaignLists = () => {
      if (isPrincipal.value) {
        getLists(`/v1/campaign?status=${tabID.value}`)
      } else if (isRetailer.value) {
        getLists(`/v1/campaign/retailer?status=${tabID.value}`)
      } else if (isSuki.value) {
        getLists(`/v1/campaign/suki?status=${tabID.value}`)
      }
    }

    const getInitialData = () => {
      getCampaignCount()
      getCampaignLists()
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      getInitialData()
    }

    onBeforeMount(() => {
      getInitialData()

      watch([dataCount, errorCount, loadingCount], () => {
        if (!loadingCount.value) {
          tabs.value = tabs.value.map(item => {
            return {
              ...item,
              enabled: isPrincipal.value
                ? campaignType.principal.includes(item.name)
                : isRetailer.value
                ? campaignType.retailer.includes(item.name)
                : isSuki.value
                ? campaignType.suki.includes(item.name)
                : false
            }
          })

          if (dataCount?.value) {
            tabs.value = tabs.value.map(item => {
              return {
                ...item,
                count: dataCount?.value[item.name]
              }
            })
          }
        }
      })

      watch([dataLists, errorLists, loadingLists], () => {
        if (loadingLists.value) {
          salesTableData.value = []
        } else {
          if (dataLists?.value) {
            salesTableData.value = dataLists?.value
              .sort((a, b) => (a.code < b.code ? -1 : 1))
              .map(item => ({
                id: item?.id,
                campaignCode: item?.campaign_code,
                campaignName: item?.campaign_name,
                campaignFrom: item?.date_from
                  ? DATE.toFriendlyDate(item?.date_from)
                  : '-',
                campaignTo: item?.date_to
                  ? DATE.toFriendlyDate(item?.date_to)
                  : '-',
                customerSegment: item?.segment_name,
                principalName: item?.principal,
                retailerName: item?.retailer_name ?? item?.retailer
              }))
          }
        }
      })

      watch([dataList, errorList, loadingList], () => {
        if (!loadingList.value) {
          if (errorList?.value) {
            showToast(
              errorList?.value?.message ??
                'There was a problem fetching customer segment.',
              'danger'
            )
          } else if (dataList?.value) {
            const data = dataList?.value?.result
            const dataHasNull = VALIDATION.hasNull(
              omit(data, ['remarks', 'all_segment_tag'])
            )

            if (dataHasNull && actionSelected.value === 'requested') {
              return showToast(
                'Some fields are still empty. It is required to fill up all the fields before we can update your campain to [requested].',
                'info'
              )
            } else {
              onUpdateCampaign()
            }
          }
        }
      })

      watch([dataUpdate, errorUpdate, loadingUpdate], () => {
        if (!loadingUpdate.value) {
          if (errorUpdate?.value) {
            showToast(
              errorUpdate?.value?.errors
                ? errorUpdate?.value?.errors[0]?.msg
                : errorUpdate?.value?.message ??
                    'There was a problem updating this campaign.',
              'danger'
            )
          } else if (dataUpdate?.value) {
            if (dataUpdate?.value) {
              getInitialData()
              showUpdateModal.value = false
              showToast(
                actionSelected.value === 'active'
                  ? `Campaign successfully set to active!`
                  : `Campaign successfully ${actionSelected.value}!`,
                'success'
              )

              if (actionSelected.value === 'setup') {
                const payload = {
                  campaign_id: submittedData.value?.id,
                  promotion_id: submittedData.value?.coupons
                }

                postUpdateSukiCoupon(`v1/campaign/suki-coupons`, payload)
              }
            }
          }
        }
      })

      watch(
        [dataUpdateSukiCoupon, errorUpdateSukiCoupon, loadingUpdateSukiCoupon],
        () => {
          if (!loadingUpdateSukiCoupon.value) {
            if (errorUpdateSukiCoupon?.value) {
              showToast(
                errorUpdateSukiCoupon?.value?.errors
                  ? errorUpdateSukiCoupon?.value?.errors[0]?.msg
                  : errorUpdateSukiCoupon?.value?.message ??
                      'There was a problem updating this campaign.',
                'danger'
              )
            } else if (dataUpdateSukiCoupon?.value) {
              if (dataUpdateSukiCoupon?.value) {
                showToast('Campaign coupon successfully updated', 'success')
              }
            }
          }
        }
      )

      watch(
        [dataDownloadMembers, errorDownloadMembers, loadingDownloadMembers],
        () => {
          if (loadingDownloadMembers.value) {
            if (!notif.value) {
              notif.value = $q.notify({
                type: 'ongoing',
                color: 'primary',
                message: 'Downloading campaign members...'
              })
            }
          } else {
            if (errorDownloadMembers?.value) {
              const errMsg = errorDownloadMembers?.value?.errors
                ? errorDownloadMembers?.value?.errors[0]?.msg
                : errorDownloadMembers?.value?.message ??
                  'There was a problem downloading campaign members.'

              notif.value({
                type: 'negative',
                color: 'danger',
                message: errMsg
              })
            } else if (dataDownloadMembers?.value) {
              if (dataDownloadMembers?.value) {
                EXPORT.exportToCSV({
                  header: [{ name: 'mobile', label: 'Mobile Number' }],
                  data: dataDownloadMembers?.value?.campaignsData,
                  filename: 'campaign_members'
                })

                notif.value({
                  type: 'positive',
                  color: 'success',
                  message: `Campaign members successfully downloaded.`
                })
              }
            }

            notif.value = null
          }
        }
      )
    })

    return {
      HELP,
      isPrincipal,
      isRetailer,
      isSuki,
      tabs,
      actionSelected,
      tabSelected,
      tabID,
      isDraft,
      isRequested,
      isRejected,
      isApproved,
      isSetup,
      isActive,
      isCompleted,
      isRequestAllowed,
      isEditAllowed,
      isApprovedAllowed,
      isRejectedAllowed,
      isSetupAllowed,
      isActiveAllowed,
      isDeleteAllowed,
      isCancelAllowed,
      isDownloadAllowed,
      hasData,
      showModal,
      showViewModal,
      showDeleteModal,
      showUpdateModal,
      selectedData,
      loadingCount,
      loadingLists,
      loadingUpdate,
      limitPage,
      pageNo,
      totalCount,
      search,
      filterForm,
      salesTableHeader,
      salesTableData,
      campaign,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasBadge,
      onToggleTab,
      onToggleModal,
      goToCreatePage,
      goToEditPage,
      exportTable,
      onDownloadMembers,
      onOpenViewModal,
      onCloseViewModal,
      onOpenUpdateModal,
      onCloseUpdateModal,
      onSubmitUpdateModal,
      onClearSearch,
      onRequest
    }
  }
}
</script>

<style lang="scss" scoped>
ol {
  display: block;
  list-style: decimal;
  padding: 0 0 0 40px;

  li {
    list-style-position: outside;
    padding-left: 10px;
  }
}
</style>
