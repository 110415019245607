<template>
  <CampaignLists />
</template>

<script>
import CampaignLists from '@/components/pages/campaign/CampaignLists'

export default {
  components: {
    CampaignLists
  }
}
</script>
